<template>
<div>
    <img :key="i" :src="getImageSrc(i)" v-for="i in 5" :style="{ 'z-index': 5-i, 'margin-left': ((i-1)*35)+'px' }">
</div>
</template>

<script>
export default {
    props: { images: Object },
    methods: {
      getImageSrc(i) {
        return `/assets/images/f${i}.svg`;
      },
    }
}
</script>

<style scoped>
img {
    border-radius: 25px;
    border: 3px solid #fff;
    position: absolute;
}

div {
    position: relative;
    height: 45px;
}
</style>